import React from 'react'
import { Page404 } from '@klickmarketing/react-components'

import Layout from '../components/Layout/Layout'

const NotFoundPage = () => (
  <Layout
    path="/404"
    seoProps={{
      title: 'Page Not Found',
    }}
  >
    <Page404 />
  </Layout>
)

export default NotFoundPage
